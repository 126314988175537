* {
  margin: 0;
}

.toggle {
  padding: 0 20px;
}

.toggle h4 {
  font-size: 1rem;
  font-weight: 100;
  text-align: center;
}

.so1screen__container {
  display: flex;
  flex-direction: column;
}

.so1screen__container__img {
  height: auto;
  position: sticky;
  background-color: rgba(255, 255, 255, 0.1);
}

.so1screen__logo {
  padding: 20px 40px;
  cursor: pointer;
}

.so1screen {
  display: flex;
  justify-content: space-between;
  height: 100vh;
}

.so1screen__video {
  flex: 0.8;
  position: relative;
}

.so1screen__video h2 {
  height: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  padding: 20px 0;
}

.so1screen__videos {
  height: 100%;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  color: white;
  padding: 60px 0;
  text-align: center;
  /* flex-wrap: wrap; */
  justify-content: left;
  margin: 0;
}

.so1screen__videos::-webkit-scrollbar {
  display: none;
}

.so1screen__videos div img {
  width: 350px; 
  height: auto; 
  margin: 0 20px; 
  transition: all 0.8s ease; 
  border-radius: 15%;
  cursor: pointer;
  border: none;
}

.so1screen__videos div img:hover {
  transition: all 0.8s ease;
  transform: scale(1.05);
  box-shadow: rgba(149, 157, 165, 0.25) 0px 8px 24px;
}

.so1screen__videos div h2 {
  font-size: 1.2rem;
  padding: 20px 0;
  font-weight: 100;
}

.so1screen__img {
  flex: 0.2;
  padding: 40px;
  background-color: whitesmoke;
  border-radius: 40px;
  margin: 0 20px 20px 20px;
  box-shadow: rgba(255, 255, 255, 0.35) 0px 8px 24px;
  background: linear-gradient(to right, red, purple);
  color: white;
  justify-content: center;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.so1screen__img::-webkit-scrollbar {
  display: none;
}

.so1screen__img p {
  font-weight: 200;
  text-align: center;
}

.so1screen__icons {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 20px;
}

.so1screen__icons p {
  padding-right: 20px;
}

.so1screen__img__map div {
  padding-bottom: 20px;
  color: white;
}

.so1screen__img__map__img {
  width: 100%;
  height: auto;
  padding: 20px 0;
  margin: 0;
  transition: all 0.8s ease;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease;
}

.so1screen__img__map__img:hover {
  transform: scale(1.09);
  transition: all 0.5s ease;
  border-radius: 25%;
}

.so1screen__img__map div p {
  padding: 0;
  margin: 0;
  justify-content: center;
}

.so1screen__img__map__price {
  font-size: 1.8rem;
}

.so1screen__img__map div button {
  /* background-color: #f04e23; */
  background-color: #111;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  margin: 10px 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.so1screen__img__map div button:hover {
  background-color: var(--unishoper-two);
  transform: scale(1.09);
  transition: all 0.5s ease;
}

.so1screen__default__title h2 {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0;
}

.so1screen__characters {
  display: flex;
  flex-direction: column;
  padding: 0;
  color: white;
  justify-content: center;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.so1screen__characters::-webkit-scrollbar {
  display: none;
}

.so1screen__characters div {
  padding: 20px 0;
}

.so1screen__characters div img {
  width: 150px;
  margin: 0;
  padding: 0;
  cursor:default;
}

.so1screen__characters div img:hover {
  transform: none;
  transition: none;
}

.so1screen__characters div h2 {
  font-size: 1.2rem;
  font-weight: 600;
  padding-top: 10px;
}

.so1screen__characters div p {
  font-size: 1rem;
  font-weight: 200;
}

.so1screen__nuevaserie {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}

.so1screen__nuevaserie__left {
  flex: 1;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  margin: 50px;
  padding: 50px;
  height: 100%;
}

.so1screen__nuevaserie__left__soon {
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  letter-spacing: 0.2rem;
  font-size: 1.8rem;
}

.so1screen__nuevaserie__left img {
  max-width: 450px;
  padding: 20px 0;
}

.so1screen__nuevaserie__left__genres {
  font-size: 1.4rem;
  opacity: 0.8;
  font-weight: lighter;
}

.so1screen__nuevaserie__left__synopsis {
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 20px 0;
}

.so1screen__nuevaserie__video {
  padding: 20px 0;
}

.so1screen__nuevaserie__right {
  flex: 1;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  
}

/* Tablet and small laptop */
@media (max-width: 799px) {

  .so1screen__video__info {
    padding: 0 20px;
  }

  .so1screen__video__info2 {
    display: none;
  }

  .so1screen__video__info__part1__title {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .so1screen__video__info__part1__title p {
    padding: 0 10px;
    display: flex;
  }

  .so1screen__video__info__part1__subtitle {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px 0;
    align-items: center;
    margin: 0;
  }

  .so1screen__video__info__part1__subtitle p {
    padding: 0 10px;
  }

  .so1screen__video__info__part1__subtitle h3 {
    font-weight: 100;
    font-size: 1rem;
    padding: 0 10px;
  }

  .so1screen__container__img {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }

  .so1screen__logo {
    padding: 30px;
    cursor: pointer;
  }

  .so1screen {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
  }

  .so1screen__video {
    /* flex: 1; */
    position: relative;
    padding-top: 56.25%;
    margin: 20px 0;
  }

  .react-player {
    /* width: 100%;
    height: 40vh;
    position: fixed; */
    position: absolute;
    top: 0;
    left: 0;
  }

  .so1screen__img {
    display: flex;
    flex: 1;
    padding: 40px;
    background-color: white;
    flex-direction: column;
    margin: 20px;
  }

  .so1screen__img__map {
    justify-content: left;
    text-align: center;
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
  }

  .so1screen__img__map div {
    flex-direction: row;
    padding: 0 20px;
  }

  .so1screen__img__map__img {
    width: 150px;
  }

  .so1screen__img__map__title {
    padding-top: 20px;
  }

  .so1screen__videos {
    padding: 40px 0;
  }

  .so1screen__videos div img {
    width: 220px; 
  }

  .so1screen__default {
    display: flex;
  }

  .so1screen__default__title h2 {
    max-width: 120px;
    align-items: center;
    margin: 0;
    padding-top: 0;
  }

  .so1screen__nuevaserie {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }
  
  .so1screen__nuevaserie__left {
    flex: 1;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin: 20px;
    padding: 40px;
    height: 100%;
  }
  
  .so1screen__nuevaserie__left__soon {
    font-family: IntroDemo-BlackCapsInline;
    font-weight: Lighter;
    letter-spacing: 0.2rem;
    font-size: 1.8rem;
  }
  
  .so1screen__nuevaserie__left img {
    max-width: 250px;
    padding: 20px 0;
  }
  
  .so1screen__nuevaserie__left__genres {
    font-size: 1.4rem;
    opacity: 0.8;
    font-weight: lighter;
  }
  
  .so1screen__nuevaserie__left__synopsis {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 20px 0;
  }
  
  .so1screen__nuevaserie__video {
    padding: 20px 0;
  }
  
  .so1screen__nuevaserie__right {
    display: none;
  }
}

/* Laptop */
@media (min-width: 800px) {
  .so1screen__video__info {
    display: none;
  }

  .so1screen__video__info__part1__title {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .so1screen__video__info__part1__title p {
    padding: 0 10px;
    display: flex;
  }

  .so1screen__video__info__part1__subtitle {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px 0;
    align-items: center;
    margin: 0;
  }

  .so1screen__video__info__part1__subtitle p {
    padding: 0 10px;
  }

  .so1screen__video__info__part1__subtitle h3 {
    font-weight: 100;
    font-size: 1rem;
    padding: 0 10px;
  }

  .so1screen__videos {
    margin: 0;
    justify-content: left;
  }

  .so1screen__default__title__icon {
    display: none;
  }
  
}

/* Desktop */
@media (max-width: 1200px) {

}