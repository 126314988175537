@import url(https://fonts.googleapis.com/css?family=Poppins);
* {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: "IntroDemo-BlackCapsInline";
  src: local("IntroDemo-BlackCapsInline"),
    url(/static/media/IntroDemo-BlackCapsInline.07618409.otf) format("opentype");
}

body, html {
  --unishoper-zero: #F9F6EE;
  --unishoper-one: #F04E23;
  --unishoper-two: purple;
  --unishoper-three: #111;
  /* background-color: 111; */
  color: white;
  background-image: linear-gradient(to right, #111, black);
}

input {
 color: #111;
}

input::-webkit-input-placeholder {
  color: #111;
 }

input::placeholder {
  color: #111;
 }

textarea {
  color: #111;
}

textarea::-webkit-input-placeholder {
  color: #111;
}

textarea::placeholder {
  color: #111;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to right, black, black);
  background-image: linear-gradient(to right, black, #111);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

* {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif, Lighter, Light, Regular, Medium, Bold;
}

.app {
  /* background-color: #111; */
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to right, black, #111);
  z-index: -1;
}

.loginScreen {
  margin: 0;
  padding: 0;
  color: white;
}

.loginScreen__box__signup {
  margin: 0;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
  z-index: 1000;
  color: white;
  background-color: rgba(17, 17, 17, 1);
  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

/* .loginScreen__logo {
  height: fit-content;
  width: 350px;
  padding: 0;
  cursor: pointer;
} */

.loginScreen__signup {
  margin: 0;
  color: #fff;
  display: flex;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginScreen__signup form {
  display: flex;
  text-align: center;
}

.loginScreen__signup form > button {
  margin: 5px;
  padding: 15px 20px;
  border: 0.25px solid white;
  background-color: transparent;
  cursor: pointer;
  color: white;
  border-radius: 20px;
  font-size: 1rem;
  transition: all 0.7s ease;
}

.loginScreen__signup form > button:hover {
  background-color: #2556cc;
  border: 0.5px solid #111;
  color: #111; 
  transition: all 0.7s ease;
}

.loginScreen__box__cover {
  color: white;
}

.loginScreen__cover__main {
  display: flex;
  justify-content: space-between;
}

.loginScreen__cover__contents {
  max-width: 450px;
  height: 100%;
  padding: 50px;
  z-index: 99;
  flex: 1 1;
}

.loginScreen__cover__contents img {
  max-width: 180px;
  margin-bottom: 10px;
}

.loginScreen__cover__contents > h1 {
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  font-size: 5rem;
  line-height: 6rem;
}

.loginScreen__cover__contents h1 > span {
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  color: var(--unishoper-one);
  /* color: #CBC2BF; */
  font-size: 1.6rem;
  line-height: 2rem;
  padding-top: 10px;
}

.loginScreen__cover__contents > p {
  padding-bottom: 15px;
  font-size: 1.4rem;
  z-index: 99;
}

.loginScreen__cover__contents button {
  cursor: pointer;
  background-color: #111;
  color: var(--unishoper-zero);
  padding: 20px;
  border-radius: 20px;
  border: none;
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  letter-spacing: 0.2rem;
  font-size: 1.4rem;
  align-items: center;
  margin: 20px 0;
}

.loginScreen__cover__contents button:hover {
  color: var(--unishoper-one);
  transition: all 0.5s ease;
}

.loginScreen__cover__contents button > img {
  max-height: 25px;
  width: 100%;
  margin: 0;
  align-items: center;
  padding-top: 10px;
}

.loginScreen__cover__img {
  flex: 1 1;
  margin: 0;
  display: flex;
  align-items: flex-end;
}

.loginScreen__cover__img img {
  width: auto;
  height: 100vh;
  margin-top: 20px;
  padding: 0;
}

.loginScreen__features {
  display: flex;
  flex-direction: column;
}

.loginScreen__features__title {
  justify-content: center;
  margin: 0 auto;
  padding: 80px 0 40px 0;
  text-align: center;
}

.loginScreen__features__title h2 {
  color: var(--unishoper-zero);
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  letter-spacing: 0.2rem;
  font-size: 1.8rem;
  padding: 20px 0;
}

.loginScreen__features__title p {
  opacity: 0.8;
  font-size: 1.4rem;
  font-weight: 100;
}

.loginScreen__featvideo {
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 0 200px;
}

.loginScreen__featvideo h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: lighter;
  padding: 0 50px;
}

.loginScreen__featvideo h3 > span {
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  letter-spacing: 0.2rem;
  font-size: 2.8rem;
  padding: 0 20px;
}

.loginScreen__featvideo video {
  flex: 0.7 1;
  padding: 20px 100px;
}

.loginScreen__featimg {
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 0 200px;
}

.loginScreen__featimg img {
  flex: 0.7 1;
  padding: 100px;
  width: 450px;
  height: 100%;
}

.loginScreen__featimg h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: lighter;
  padding: 0 20px 0 50px;
}

.loginScreen__featimg h3 > span {
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  letter-spacing: 0.2rem;
  font-size: 2.8rem;
  padding: 0 20px;
}

.so1screen__estrenoserie {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}

.so1screen__estrenoserie__left {
  flex: 1 1;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  margin: 50px;
  padding: 50px;
  height: 100%;
}

.so1screen__estrenoserie__left__soon {
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  letter-spacing: 0.2rem;
  font-size: 1.8rem;
}

.so1screen__estrenoserie__left img {
  max-width: 200px;
  padding: 20px 0;
}

.so1screen__estrenoserie__left__genres {
  font-size: 1.4rem;
  opacity: 0.8;
  font-weight: lighter;
}

.so1screen__estrenoserie__left__synopsis {
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 20px 0;
}

.so1screen__estrenoserie__video {
  padding: 20px 0;
}

.so1screen__estrenoserie__video button {
  background-color: var(--unishoper-one);
  border-radius: 20px;
  color: var(--unishoper-zero);
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
}

.so1screen__estrenoserie__video button:hover {
  color: var(--unishoper-three);
  transition: all 0.5s ease;
}

.so1screen__estrenoserie__right {
  flex: 1 1;
}

/* Phones and Tablet*/

@media (max-width: 799px) {

  .loginScreen__cover__main {
    flex-direction: column;
  }

  .loginScreen__cover__contents {
    font-size: 1.2rem;
    max-width: 450px;
    height: 70vh;
    padding: 20px;
    justify-content: center;
    z-index: 99;
    flex: 1 1;
    margin: 0 auto;
  }

  .loginScreen__cover__contents > h1 {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  .loginScreen__cover__img img{
    width: auto;
    width: 100%;
    height: 100%;
  }

  .so1screen__estrenoserie {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }
  
  .so1screen__estrenoserie__left {
    flex: 1 1;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin: 20px;
    padding: 40px;
    height: 100%;
  }
  
  .so1screen__estrenoserie__left__soon {
    font-family: IntroDemo-BlackCapsInline;
    font-weight: Lighter;
    letter-spacing: 0.2rem;
    font-size: 1.8rem;
  }
  
  .so1screen__estrenoserie__left img {
    max-width: 250px;
    padding: 20px 0;
  }
  
  .so1screen__estrenoserie__left__genres {
    font-size: 1.4rem;
    opacity: 0.8;
    font-weight: lighter;
  }
  
  .so1screen__estrenoserie__left__synopsis {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 20px 0;
  }
  
  .so1screen__estrenoserie__video {
    padding: 20px 0;
  }
  
  .so1screen__estrenoserie__right {
    display: none;
  }

  .loginScreen__features {
    display: flex;
    flex-direction: column;
  }
  
  .loginScreen__features__title {
    justify-content: center;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
  }
  
  .loginScreen__features__title h2 {
    color: var(--unishoper-zero);
    font-family: IntroDemo-BlackCapsInline;
    font-weight: Lighter;
    letter-spacing: 0.2rem;
    font-size: 1.8rem;
    padding: 20px 0;
  }
  
  .loginScreen__features__title p {
    opacity: 0.8;
    font-size: 1.4rem;
    font-weight: 100;
  }
  
  .loginScreen__featvideo {
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    padding: 0 20px;
  }
  
  .loginScreen__featvideo h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 1.4rem;
    font-weight: lighter;
    padding: 0;
  }
  
  .loginScreen__featvideo h3 > span {
    font-family: IntroDemo-BlackCapsInline;
    font-weight: Lighter;
    letter-spacing: 0.2rem;
    font-size: 2.8rem;
    padding: 0 20px 0 0;
  }
  
  .loginScreen__featvideo video {
    flex: 1 1;
    padding: 40px 20px;
    justify-content: center;
    margin: 0;
  }
  
  .loginScreen__featimg {
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    padding: 0 20px;
  }
  
  .loginScreen__featimg img {
    flex: 1 1;
    padding: 40px 0;
    width: 300px;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
  }
  
  .loginScreen__featimg h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 1.4rem;
    font-weight: lighter;
    padding: 0;
  }
  
  .loginScreen__featimg h3 > span {
    font-family: IntroDemo-BlackCapsInline;
    font-weight: Lighter;
    letter-spacing: 0.2rem;
    font-size: 2.8rem;
    padding: 0 20px 0 0;
  }
  
}

/* Tablet and small laptop */
@media (min-width: 800px) {
  
}

/* Laptop */
@media (max-width: 992px) {
  
}

/* Desktop */
@media (max-width: 1300px) {
  
}

.copyright {
  margin: 0;
  color: darkgrey;
  opacity: 0.7;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  font-size: 0.9rem;
}

.copyright > p {
  padding: 5px;
  justify-content: center;
  text-align: center;
}

* {
  margin: 0;
}

.toggle {
  padding: 0 20px;
}

.toggle h4 {
  font-size: 1rem;
  font-weight: 100;
  text-align: center;
}

.so1screen__container {
  display: flex;
  flex-direction: column;
}

.so1screen__container__img {
  height: auto;
  position: -webkit-sticky;
  position: sticky;
  background-color: rgba(255, 255, 255, 0.1);
}

.so1screen__logo {
  padding: 20px 40px;
  cursor: pointer;
}

.so1screen {
  display: flex;
  justify-content: space-between;
  height: 100vh;
}

.so1screen__video {
  flex: 0.8 1;
  position: relative;
}

.so1screen__video h2 {
  height: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  padding: 20px 0;
}

.so1screen__videos {
  height: 100%;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  color: white;
  padding: 60px 0;
  text-align: center;
  /* flex-wrap: wrap; */
  justify-content: left;
  margin: 0;
}

.so1screen__videos::-webkit-scrollbar {
  display: none;
}

.so1screen__videos div img {
  width: 350px; 
  height: auto; 
  margin: 0 20px; 
  transition: all 0.8s ease; 
  border-radius: 15%;
  cursor: pointer;
  border: none;
}

.so1screen__videos div img:hover {
  transition: all 0.8s ease;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: rgba(149, 157, 165, 0.25) 0px 8px 24px;
}

.so1screen__videos div h2 {
  font-size: 1.2rem;
  padding: 20px 0;
  font-weight: 100;
}

.so1screen__img {
  flex: 0.2 1;
  padding: 40px;
  background-color: whitesmoke;
  border-radius: 40px;
  margin: 0 20px 20px 20px;
  box-shadow: rgba(255, 255, 255, 0.35) 0px 8px 24px;
  background: linear-gradient(to right, red, purple);
  color: white;
  justify-content: center;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.so1screen__img::-webkit-scrollbar {
  display: none;
}

.so1screen__img p {
  font-weight: 200;
  text-align: center;
}

.so1screen__icons {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 20px;
}

.so1screen__icons p {
  padding-right: 20px;
}

.so1screen__img__map div {
  padding-bottom: 20px;
  color: white;
}

.so1screen__img__map__img {
  width: 100%;
  height: auto;
  padding: 20px 0;
  margin: 0;
  transition: all 0.8s ease;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease;
}

.so1screen__img__map__img:hover {
  -webkit-transform: scale(1.09);
          transform: scale(1.09);
  transition: all 0.5s ease;
  border-radius: 25%;
}

.so1screen__img__map div p {
  padding: 0;
  margin: 0;
  justify-content: center;
}

.so1screen__img__map__price {
  font-size: 1.8rem;
}

.so1screen__img__map div button {
  /* background-color: #f04e23; */
  background-color: #111;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  margin: 10px 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.so1screen__img__map div button:hover {
  background-color: var(--unishoper-two);
  -webkit-transform: scale(1.09);
          transform: scale(1.09);
  transition: all 0.5s ease;
}

.so1screen__default__title h2 {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0;
}

.so1screen__characters {
  display: flex;
  flex-direction: column;
  padding: 0;
  color: white;
  justify-content: center;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.so1screen__characters::-webkit-scrollbar {
  display: none;
}

.so1screen__characters div {
  padding: 20px 0;
}

.so1screen__characters div img {
  width: 150px;
  margin: 0;
  padding: 0;
  cursor:default;
}

.so1screen__characters div img:hover {
  -webkit-transform: none;
          transform: none;
  transition: none;
}

.so1screen__characters div h2 {
  font-size: 1.2rem;
  font-weight: 600;
  padding-top: 10px;
}

.so1screen__characters div p {
  font-size: 1rem;
  font-weight: 200;
}

.so1screen__nuevaserie {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}

.so1screen__nuevaserie__left {
  flex: 1 1;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  margin: 50px;
  padding: 50px;
  height: 100%;
}

.so1screen__nuevaserie__left__soon {
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  letter-spacing: 0.2rem;
  font-size: 1.8rem;
}

.so1screen__nuevaserie__left img {
  max-width: 450px;
  padding: 20px 0;
}

.so1screen__nuevaserie__left__genres {
  font-size: 1.4rem;
  opacity: 0.8;
  font-weight: lighter;
}

.so1screen__nuevaserie__left__synopsis {
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 20px 0;
}

.so1screen__nuevaserie__video {
  padding: 20px 0;
}

.so1screen__nuevaserie__right {
  flex: 1 1;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  
}

/* Tablet and small laptop */
@media (max-width: 799px) {

  .so1screen__video__info {
    padding: 0 20px;
  }

  .so1screen__video__info2 {
    display: none;
  }

  .so1screen__video__info__part1__title {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .so1screen__video__info__part1__title p {
    padding: 0 10px;
    display: flex;
  }

  .so1screen__video__info__part1__subtitle {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px 0;
    align-items: center;
    margin: 0;
  }

  .so1screen__video__info__part1__subtitle p {
    padding: 0 10px;
  }

  .so1screen__video__info__part1__subtitle h3 {
    font-weight: 100;
    font-size: 1rem;
    padding: 0 10px;
  }

  .so1screen__container__img {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }

  .so1screen__logo {
    padding: 30px;
    cursor: pointer;
  }

  .so1screen {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
  }

  .so1screen__video {
    /* flex: 1; */
    position: relative;
    padding-top: 56.25%;
    margin: 20px 0;
  }

  .react-player {
    /* width: 100%;
    height: 40vh;
    position: fixed; */
    position: absolute;
    top: 0;
    left: 0;
  }

  .so1screen__img {
    display: flex;
    flex: 1 1;
    padding: 40px;
    background-color: white;
    flex-direction: column;
    margin: 20px;
  }

  .so1screen__img__map {
    justify-content: left;
    text-align: center;
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
  }

  .so1screen__img__map div {
    flex-direction: row;
    padding: 0 20px;
  }

  .so1screen__img__map__img {
    width: 150px;
  }

  .so1screen__img__map__title {
    padding-top: 20px;
  }

  .so1screen__videos {
    padding: 40px 0;
  }

  .so1screen__videos div img {
    width: 220px; 
  }

  .so1screen__default {
    display: flex;
  }

  .so1screen__default__title h2 {
    max-width: 120px;
    align-items: center;
    margin: 0;
    padding-top: 0;
  }

  .so1screen__nuevaserie {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }
  
  .so1screen__nuevaserie__left {
    flex: 1 1;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin: 20px;
    padding: 40px;
    height: 100%;
  }
  
  .so1screen__nuevaserie__left__soon {
    font-family: IntroDemo-BlackCapsInline;
    font-weight: Lighter;
    letter-spacing: 0.2rem;
    font-size: 1.8rem;
  }
  
  .so1screen__nuevaserie__left img {
    max-width: 250px;
    padding: 20px 0;
  }
  
  .so1screen__nuevaserie__left__genres {
    font-size: 1.4rem;
    opacity: 0.8;
    font-weight: lighter;
  }
  
  .so1screen__nuevaserie__left__synopsis {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 20px 0;
  }
  
  .so1screen__nuevaserie__video {
    padding: 20px 0;
  }
  
  .so1screen__nuevaserie__right {
    display: none;
  }
}

/* Laptop */
@media (min-width: 800px) {
  .so1screen__video__info {
    display: none;
  }

  .so1screen__video__info__part1__title {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .so1screen__video__info__part1__title p {
    padding: 0 10px;
    display: flex;
  }

  .so1screen__video__info__part1__subtitle {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px 0;
    align-items: center;
    margin: 0;
  }

  .so1screen__video__info__part1__subtitle p {
    padding: 0 10px;
  }

  .so1screen__video__info__part1__subtitle h3 {
    font-weight: 100;
    font-size: 1rem;
    padding: 0 10px;
  }

  .so1screen__videos {
    margin: 0;
    justify-content: left;
  }

  .so1screen__default__title__icon {
    display: none;
  }
  
}

/* Desktop */
@media (max-width: 1200px) {

}
.nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  align-items: center;
  justify-content: space-between;
  /* padding: 25px 0px 20px 0px; */
  /* height: 45px; */
  z-index: 1000;
  color: white;
  background: linear-gradient(#111, rgba(17, 17, 17, 0.5));
  height: auto;
  padding: 15px 0 10px 0;
  margin: 0;

  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.nav__black {
  background: linear-gradient(#111, rgba(17, 17, 17, 0.5));
}

.nav__contents {
  display: flex;
  margin: 0 40px;
  padding: 5px;
  justify-content: space-between;
}

.nav__logo img {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 150px;
  /* padding-top: 5px; */
  /* padding-right: 20px; */
  cursor: pointer;
  align-items: center;
}

/* .nav__search {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid lightgray;
  height: 13px;
  padding: 10px 10px;
  border-radius: 999px;
  width: 75vw;
  margin: 0 auto;
  max-width: 250px;
  color: whitesmoke;
}

.nav__search:hover {
  background-color: lightgray;
  opacity: 1;
  transition: 1s;
  color: #111;
}

.nav__search form {
  display: flex;
}

.nav__search form > input {
  flex: 1;
  padding: 0px 10px;
  font-size: medium;
  border: none;
  background: none;
  color: whitesmoke;
}

.nav__search form > input:hover {
  color: black;
}

.nav__search form > input:focus {
  outline-width: 0;
}

::-webkit-input-placeholder {
  opacity: 0.75;
}

.nav__searchButton {
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  opacity: 0.75;
  padding-top: 5px;
}

.nav__searchButton:focus {
  outline: none;
} */

:-moz-placeholder {
  /* Firefox 18- */
  color: white;
  font-weight: bold;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: white;
  font-weight: bold;
}

:-ms-input-placeholder {
  color: white;
  font-weight: lighter;
}

.nav__icons {
  color: white;
  list-style: none;
  padding: 0 40px;
  margin: 0;
  display: flex;
  align-items: center;
}

.nav__icon > .MuiSvgIcon-root {
  font-size: 25px;
  margin-left: auto;
  margin-right: auto;
}

.nav__icon:hover > .MuiSvgIcon-root {
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
}

.nav__icon {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 75px;
}

.nav__icon > p {
  font-size: small;
  display: none;
  letter-spacing: 2px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2px;
  transition-timing-function: ease-in;
  transition: all 0.5s;
  text-align: center;
}

.nav__icon--active > p {
  display: inline-flex !important;
}

.nav__icon:hover > p {
  display: inline-flex;
}

.nav__profile {
  display: flex;
  right: 0;
  padding-right: 0px;
  cursor: pointer;
}

.nav__profile > p {
  margin: auto;
  align-items: center;
  justify-content: center;
}

.nav__profile__block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
}

.nav__avatar {
  padding: 0 15px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.nav__profile__dropdown {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Phones and Tablet*/
@media (max-width: 799px) {
  .nav {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: auto;
    z-index: 1000;
    color: white;
    background-color: rgba(17, 17, 17, 0.8);

    /* Animaciones */
    transition-timing-function: ease-in;
    transition: all 0.5s;
  }

  /* .nav__black {
    background-color: #111;
    opacity: 1;
  } */

  .nav__contents {
    margin: 0 auto;
    top: 0;
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
    align-items: center;
  }

  .nav__logo img {
    width: 120px;
    justify-content: left;
    align-items: center;
    text-align: left;
    cursor: pointer;
    left: 0;
  }

  .nav__profile {
    display: flex;
    margin: 0 auto;
    right: 0;
    justify-content: right;
  }

  .nav__icons {
    display: flex;
    margin: 0 auto;
    right: 0;
    justify-content: right;
    padding: 0;
  }

  .nav__icon {
    display: none;
  }

  /* .nav__search {
    display: grid;
    justify-content: center;
    align-items: center;
    border: 2px solid lightgray;
    height: 13px;
    padding: 10px 10px;
    border-radius: 999px;
    max-width: 250px;
    color: whitesmoke;
  }

  .nav__search:hover {
    background-color: lightgray;
    opacity: 1;
    transition: 1s;
    color: #111;
  }

  .nav__search form {
    display: inline-flex;
  }

  .nav__search form > input {
    flex: 1;
    padding: 0px 20px;
    font-size: small;
    border: none;
    background: none;
    color: whitesmoke;
  }

  .nav__search form > input:hover {
    color: black;
  }

  .nav__search form > input:focus {
    outline-width: 0;
  }

  ::-webkit-input-placeholder {
    opacity: 0.75;
  }

  .nav__searchButton {
    background: none;
    border: none;
    color: white;
    font-size: 2em;
    opacity: 0.75;
    display: none;
  }

  .nav__searchButton:focus {
    outline: none;
  } */

  :-moz-placeholder {
    /* Firefox 18- */
    color: white;
    font-weight: bold;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: white;
    font-weight: bold;
  }

  :-ms-input-placeholder {
    color: white;
    font-weight: lighter;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  
}

/* Laptop */
@media (max-width: 992px) {
  
}

/* Desktop */
@media (max-width: 1200px) {
}

.headsign {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.headsign__idlist {
    opacity: 0.5;
    font-size: 0.8rem;
    align-items: center;
}

.continueshopping {
    display: flex;
    align-items: center;
}

.continueshopping p {
    padding-left: 10px;
}

.productScreen {
    padding: 0 20px;
}

.productScreen__container {
    display: flex;
    flex-direction: column;
}

.productScreen__container__box {
    display: flex;
    flex-direction: column;
}

.productScreen__container__boximg {
    max-width: 800px;
    height: 100%;
    padding: 0 40px;
    border-radius: 20%;
}

.productScreen__info {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
}

.productScreen__info__div__brandimg {
    padding: 20px 0;
}

.productScreen__info__brandimg {
    width: 200px;
    height: 100%;
}

.productScreen__info__cod {
    opacity: 0.7;
    font-size: 0.8rem;
}

.productScreen__info__productname > span {
    opacity: 0.8;
}

.productScreen__info__brand {
    font-size: 1.2rem;
}

.productScreen__info__presentation {
    padding-top: 20px;
    font-size: 1rem;
}

.productScreen__info__category {
    padding-bottom: 30px;
    font-size: 1rem;
}

.productScreen__info__price {
    font-size: 2rem;
    color: red;
}

.productScreen__info__price > .productScreen__info__price__currency {
    font-size: 1rem;
}

.productScreen__info__metric {
    opacity: 0.7;
    font-size: 0.8rem;
}

.productScreen__carrito {
    padding: 20px 0;
}

.productScreen__carrito__buttons {
    display: flex;
    padding: 20px 0 10px 0;
}

.productScreen__carrito__buttons button {
    margin: 0;
    width: 50px;
    height: 50px;
    background-color: rgba(255,255,255,0.5);
    border-radius: 7px;
    font-size: 1.2rem;
    align-items: center;
}

.productScreen__carrito__buttons p {
    margin: 0;
    padding: 0 20px;
    font-size: 1.2;
    align-items: center;
}

.productScreen__carrito__comment {
    display: flex;
}

.productScreen__carrito__comment textarea {
    padding: 12px 10px;
    outline-width: 0;
    /* height: 12px; */
    border: solid 1px #111;
    border-radius: 5px;
    margin: 30px auto 0 auto;
    text-align: center;
    width: 100%;
    color: #111;
  }
  
  .productScreen__carrito__comment textarea::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 1);
    text-align: center;
    align-items: center;
  }
  
  .productScreen__carrito__comment textarea::placeholder {
    color: rgba(0, 0, 0, 1);
    text-align: center;
    align-items: center;
  }

.productScreen__carrito__add {
    background-color: #111;
    padding: 20px;
    width: 100%;
    border: solid 1px var(--unishoper-zero);
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    color: var(--unishoper-zero);
    margin-top: 40px;
}

.productScreen__carrito__add:hover {
    color: var(--unishoper-one);
    transition: all 0.5s ease;
}

.productScreen__recommended {
    margin-top: 40px;
    padding: 40px 0 20px 0;
    border-top: solid 1px rgba(255, 0, 0, 0.2);
}

.productScreen__recommended h2 {
    font-size: 1.8rem;
    padding-bottom: 20px;
}

.productScreen__recommended__results {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* padding: 20px; */
    align-items: flex-start;
}

/* Tablet and small laptop */
@media (max-width: 799px) {
    .productScreen__info {
        text-align: center;
    }

    .productScreen__carrito__buttons {
        text-align: center;
        justify-content: center;
        margin: 0;
    }

    .productScreen__container__boximg {
        padding: 0 20px;
        border-radius: 50%;
    }

    .productScreen__info__brandimg {
        width: 150px;
        height: 100%;
    }
}

/* Desktop */
@media (min-width: 800px) {
    .productScreen__container__box{
        display: flex;
        flex-direction: row;
    }

    .productScreen__info__brandimg {
    width: 200px;
    height: 100%;
    border-radius: none;
    }
}

option {
    color: #111;
    padding: 5px 0;
}

.checkoutguestScreen__resumen {
    display: flex;
    flex-direction: column;
    padding: 20px 80px 10px 80px;
}

.checkoutguestScreen__resumen__title {
    font-size: 1.4rem;
    text-align: center;
    border-bottom: solid 1px rgba(255, 0, 0, 0.2);
    padding-bottom: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.checkoutguestScreen__resumen__title span {
    opacity: 0.8;
    font-size: 1rem;
    color: var(--unishoper-three);
    font-weight: 600;
}

.checkoutguestScreen__resumen__math  {
    display: flex;
    flex-direction: column;
}

.checkoutguestScreen__resumen__inline {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    padding-bottom: 5px;
}

.checkoutguestScreen__continueshopping {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 40px 20px;
    text-align: center;
    background-color: var(--unishoper-zero);
    margin-top: 20px;
}

.checkoutguestScreen__continueshopping p {
    font-size: 0.8rem;
    padding: 10px 0;
}

.checkoutguestScreen__continueshopping button {
    background-color: var(--unishoper-one);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    color: var(--almerqao-three);
    padding: 10px;
}

.checkoutguestScreen__continueshopping button:hover {
    background-color: #111;
    transition: all 0.5s ease;
    color: var(--unishoper-zero);
}

.checkoutguestScreen__tabs {
    background-color: var(--unishoper-one);
    padding: 20px 0;
}

.checkoutguestScreen__title {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px 0 30px 0;
}

.checkoutguestScreen__title span {
    opacity: 0.8;
    font-size: 1rem;
    color: var(--unishoper-three);
    font-weight: 600;
}

.checkoutguestScreen__yape {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.checkoutguestScreen__yape img {
    margin: 0 auto;
    max-width: 300px;
    justify-content: center;
    text-align: center;
}

.checkoutguestScreen__yape p {
    font-size: 1.4rem;
    text-align: center;
    padding: 5px 0;
    font-weight: 700;
    align-items: center;
}

.checkoutguestScreen__yape span {
    font-size: 1rem;
    text-align: center;
}

.checkoutguestScreen__transferencia {
    text-align: center;
    padding: 20px;
}

.checkoutguestScreen__data {
    padding: 20px;
}

.checkoutguestScreen__data__title {
    text-align: center;
    padding: 20px 0 30px 0;
    display: flex;
    flex-direction: column;
}

.checkoutguestScreen__data__title span {
    opacity: 0.8;
    font-size: 1rem;
    color: var(--unishoper-three);
    font-weight: 600;
}

.checkoutguestScreen__data__form {
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.checkoutguestScreen__data__form ul {
    padding: 0 20px;
}

.checkoutguestScreen__data__form ul li {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    width: 100%;
}

.checkoutguestScreen__data__form ul li input {
    padding: 10px 20px;
    background-color: var(--unishoper-zero);
    border: solid 1px var(--unishoper-one);
    margin: 10px 0;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    width: 100%;
}

.checkoutguestScreen__data__form ul li input::-webkit-input-placeholder {
    opacity: 0.8;
}

.checkoutguestScreen__data__form ul li input::placeholder {
    opacity: 0.8;
}

.checkoutguestScreen__data__form__select p {
    font-size: 1rem;
    text-align: center;
    padding-top: 10px;
}

.checkoutguestScreen__data__form__select select {
    padding: 10px 20px;
    background-color: var(--unishoper-zero);
    border: solid 1px var(--unishoper-one);
    margin: 10px 0;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    width: 100%;
}

.checkoutguestScreen__paybutton {
    display: flex;
    padding: 0 40px 80px 40px;
}

.checkoutguestScreen__paybutton button {
    margin: 0 auto;
    padding: 20px;
    background-color: var(--unishoper-one);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    color: var(--unishoper-zero);
    justify-content: center;
    text-align: center;
    width: 100%;
}

.checkoutguestScreen__paybutton button:hover {
    background-color: #111;
    transition: all 0.5s ease;
    color: var(--unishoper-zero);
}

/* Mobile, Tablet and small laptop */
@media (max-width: 799px) {
  
}

/* Laptop */
@media (max-width: 992px) {
  
}

/* Desktop */
@media (max-width: 1300px) {
  
}
.paypalboxguest {
  display: grid;
  place-items: center;
  width: 100%;
}

.paypalboxguest__contents {
  padding: 20px 0;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.paypalboxguest__contents__donation {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

/* .paypalboxguest__contents__donation > p {
  color: #111;
  padding-right: 10px;
}

.paypalboxguest__contents__donation input {
  height: 12px;
  border: 1px solid;
  border-radius: 5px;
  width: auto;
  color: #111;
  font-size: 1.2rem;
  padding: 12px;
}
 */
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.iconLink {
  margin: 0 1rem;
}

.myproductlist__checkout {
    padding: 20px 20px 80px 20px;
}

.myproductlist__checkout__emptymsg {
    padding-top: 10px;
}

.myproductlist__checkout__emptymsg span {
    color: var(--unishoper-one);
}

.myproductlist__checkout__subtitle span {
    opacity: 0.5;
    font-size: 1rem;
    padding-left: 10px;
    font-weight: 400;
}

.myproductlist__checkout__button {
    padding: 10px 20px;
    border: none;
    background-color: var(--unishoper-one);
    border-radius: 10px;
    margin: 20px 0;
    color: var(--unishoper-zero);
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.5s ease;
}

.myproductlist__checkout__button:hover {
    background-color: #111;
    transition: all 0.5s ease;
}

.myproductlist__checkout__wrap {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.myproductlist__checkout__buttons {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}
.productCart {
  display: flex;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.productCart__info {
  padding-bottom: 10px;
  max-width: 220px;
}

.productCart__info p {
  display:flex;
  flex-direction: column;
  padding-top: 5px;
}

.productCart__container {
  flex-direction: column;
  padding: 30px;
}

.productCart__image {
  object-fit: contain;
  width: 160px;
  height: 160px;
}

.productCart__theme {
  font-weight: bold;
}

.productCart__span {
  font-size: 0.8rem;
  opacity: 0.8;
}

.productCart__removebutton {
  padding: 15px;
  border: none;
  background-color: lightgray;
  cursor: pointer;
  font-weight: 400;
  border-radius: 10px;
}

.productCart__removebutton:hover {
  background-color: red;
  border: none;
  color: white;
}

