@import url("https://fonts.googleapis.com/css?family=Poppins");

* {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif, Lighter, Light, Regular, Medium, Bold;
}

.app {
  /* background-color: #111; */
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to right, black, #111);
  z-index: -1;
}
