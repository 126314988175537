.myproductlist__checkout {
    padding: 20px 20px 80px 20px;
}

.myproductlist__checkout__emptymsg {
    padding-top: 10px;
}

.myproductlist__checkout__emptymsg span {
    color: var(--unishoper-one);
}

.myproductlist__checkout__subtitle span {
    opacity: 0.5;
    font-size: 1rem;
    padding-left: 10px;
    font-weight: 400;
}

.myproductlist__checkout__button {
    padding: 10px 20px;
    border: none;
    background-color: var(--unishoper-one);
    border-radius: 10px;
    margin: 20px 0;
    color: var(--unishoper-zero);
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.5s ease;
}

.myproductlist__checkout__button:hover {
    background-color: #111;
    transition: all 0.5s ease;
}

.myproductlist__checkout__wrap {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.myproductlist__checkout__buttons {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}