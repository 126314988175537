* {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: "IntroDemo-BlackCapsInline";
  src: local("IntroDemo-BlackCapsInline"),
    url("./fonts/IntroDemo-BlackCapsInline.otf") format("opentype");
}

body, html {
  --unishoper-zero: #F9F6EE;
  --unishoper-one: #F04E23;
  --unishoper-two: purple;
  --unishoper-three: #111;
  /* background-color: 111; */
  color: white;
  background-image: linear-gradient(to right, #111, black);
}

input {
 color: #111;
}

input::placeholder {
  color: #111;
 }

textarea {
  color: #111;
}

textarea::placeholder {
  color: #111;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to right, black, black);
  background-image: linear-gradient(to right, black, #111);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
