.headsign {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.headsign__idlist {
    opacity: 0.5;
    font-size: 0.8rem;
    align-items: center;
}

.continueshopping {
    display: flex;
    align-items: center;
}

.continueshopping p {
    padding-left: 10px;
}

.productScreen {
    padding: 0 20px;
}

.productScreen__container {
    display: flex;
    flex-direction: column;
}

.productScreen__container__box {
    display: flex;
    flex-direction: column;
}

.productScreen__container__boximg {
    max-width: 800px;
    height: 100%;
    padding: 0 40px;
    border-radius: 20%;
}

.productScreen__info {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
}

.productScreen__info__div__brandimg {
    padding: 20px 0;
}

.productScreen__info__brandimg {
    width: 200px;
    height: 100%;
}

.productScreen__info__cod {
    opacity: 0.7;
    font-size: 0.8rem;
}

.productScreen__info__productname > span {
    opacity: 0.8;
}

.productScreen__info__brand {
    font-size: 1.2rem;
}

.productScreen__info__presentation {
    padding-top: 20px;
    font-size: 1rem;
}

.productScreen__info__category {
    padding-bottom: 30px;
    font-size: 1rem;
}

.productScreen__info__price {
    font-size: 2rem;
    color: red;
}

.productScreen__info__price > .productScreen__info__price__currency {
    font-size: 1rem;
}

.productScreen__info__metric {
    opacity: 0.7;
    font-size: 0.8rem;
}

.productScreen__carrito {
    padding: 20px 0;
}

.productScreen__carrito__buttons {
    display: flex;
    padding: 20px 0 10px 0;
}

.productScreen__carrito__buttons button {
    margin: 0;
    width: 50px;
    height: 50px;
    background-color: rgba(255,255,255,0.5);
    border-radius: 7px;
    font-size: 1.2rem;
    align-items: center;
}

.productScreen__carrito__buttons p {
    margin: 0;
    padding: 0 20px;
    font-size: 1.2;
    align-items: center;
}

.productScreen__carrito__comment {
    display: flex;
}

.productScreen__carrito__comment textarea {
    padding: 12px 10px;
    outline-width: 0;
    /* height: 12px; */
    border: solid 1px #111;
    border-radius: 5px;
    margin: 30px auto 0 auto;
    text-align: center;
    width: 100%;
    color: #111;
  }
  
  .productScreen__carrito__comment textarea::placeholder {
    color: rgba(0, 0, 0, 1);
    text-align: center;
    align-items: center;
  }

.productScreen__carrito__add {
    background-color: #111;
    padding: 20px;
    width: 100%;
    border: solid 1px var(--unishoper-zero);
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    color: var(--unishoper-zero);
    margin-top: 40px;
}

.productScreen__carrito__add:hover {
    color: var(--unishoper-one);
    transition: all 0.5s ease;
}

.productScreen__recommended {
    margin-top: 40px;
    padding: 40px 0 20px 0;
    border-top: solid 1px rgba(255, 0, 0, 0.2);
}

.productScreen__recommended h2 {
    font-size: 1.8rem;
    padding-bottom: 20px;
}

.productScreen__recommended__results {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* padding: 20px; */
    align-items: flex-start;
}

/* Tablet and small laptop */
@media (max-width: 799px) {
    .productScreen__info {
        text-align: center;
    }

    .productScreen__carrito__buttons {
        text-align: center;
        justify-content: center;
        margin: 0;
    }

    .productScreen__container__boximg {
        padding: 0 20px;
        border-radius: 50%;
    }

    .productScreen__info__brandimg {
        width: 150px;
        height: 100%;
    }
}

/* Desktop */
@media (min-width: 800px) {
    .productScreen__container__box{
        display: flex;
        flex-direction: row;
    }

    .productScreen__info__brandimg {
    width: 200px;
    height: 100%;
    border-radius: none;
    }
}
