.loginScreen {
  margin: 0;
  padding: 0;
  color: white;
}

.loginScreen__box__signup {
  margin: 0;
  top: 0;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
  z-index: 1000;
  color: white;
  background-color: rgba(17, 17, 17, 1);
  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

/* .loginScreen__logo {
  height: fit-content;
  width: 350px;
  padding: 0;
  cursor: pointer;
} */

.loginScreen__signup {
  margin: 0;
  color: #fff;
  display: flex;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginScreen__signup form {
  display: flex;
  text-align: center;
}

.loginScreen__signup form > button {
  margin: 5px;
  padding: 15px 20px;
  border: 0.25px solid white;
  background-color: transparent;
  cursor: pointer;
  color: white;
  border-radius: 20px;
  font-size: 1rem;
  transition: all 0.7s ease;
}

.loginScreen__signup form > button:hover {
  background-color: #2556cc;
  border: 0.5px solid #111;
  color: #111; 
  transition: all 0.7s ease;
}

.loginScreen__box__cover {
  color: white;
}

.loginScreen__cover__main {
  display: flex;
  justify-content: space-between;
}

.loginScreen__cover__contents {
  max-width: 450px;
  height: 100%;
  padding: 50px;
  z-index: 99;
  flex: 1;
}

.loginScreen__cover__contents img {
  max-width: 180px;
  margin-bottom: 10px;
}

.loginScreen__cover__contents > h1 {
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  font-size: 5rem;
  line-height: 6rem;
}

.loginScreen__cover__contents h1 > span {
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  color: var(--unishoper-one);
  /* color: #CBC2BF; */
  font-size: 1.6rem;
  line-height: 2rem;
  padding-top: 10px;
}

.loginScreen__cover__contents > p {
  padding-bottom: 15px;
  font-size: 1.4rem;
  z-index: 99;
}

.loginScreen__cover__contents button {
  cursor: pointer;
  background-color: #111;
  color: var(--unishoper-zero);
  padding: 20px;
  border-radius: 20px;
  border: none;
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  letter-spacing: 0.2rem;
  font-size: 1.4rem;
  align-items: center;
  margin: 20px 0;
}

.loginScreen__cover__contents button:hover {
  color: var(--unishoper-one);
  transition: all 0.5s ease;
}

.loginScreen__cover__contents button > img {
  max-height: 25px;
  width: 100%;
  margin: 0;
  align-items: center;
  padding-top: 10px;
}

.loginScreen__cover__img {
  flex: 1;
  margin: 0;
  display: flex;
  align-items: flex-end;
}

.loginScreen__cover__img img {
  width: auto;
  height: 100vh;
  margin-top: 20px;
  padding: 0;
}

.loginScreen__features {
  display: flex;
  flex-direction: column;
}

.loginScreen__features__title {
  justify-content: center;
  margin: 0 auto;
  padding: 80px 0 40px 0;
  text-align: center;
}

.loginScreen__features__title h2 {
  color: var(--unishoper-zero);
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  letter-spacing: 0.2rem;
  font-size: 1.8rem;
  padding: 20px 0;
}

.loginScreen__features__title p {
  opacity: 0.8;
  font-size: 1.4rem;
  font-weight: 100;
}

.loginScreen__featvideo {
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 0 200px;
}

.loginScreen__featvideo h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: lighter;
  padding: 0 50px;
}

.loginScreen__featvideo h3 > span {
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  letter-spacing: 0.2rem;
  font-size: 2.8rem;
  padding: 0 20px;
}

.loginScreen__featvideo video {
  flex: 0.7;
  padding: 20px 100px;
}

.loginScreen__featimg {
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 0 200px;
}

.loginScreen__featimg img {
  flex: 0.7;
  padding: 100px;
  width: 450px;
  height: 100%;
}

.loginScreen__featimg h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: lighter;
  padding: 0 20px 0 50px;
}

.loginScreen__featimg h3 > span {
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  letter-spacing: 0.2rem;
  font-size: 2.8rem;
  padding: 0 20px;
}

.so1screen__estrenoserie {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}

.so1screen__estrenoserie__left {
  flex: 1;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  margin: 50px;
  padding: 50px;
  height: 100%;
}

.so1screen__estrenoserie__left__soon {
  font-family: IntroDemo-BlackCapsInline;
  font-weight: Lighter;
  letter-spacing: 0.2rem;
  font-size: 1.8rem;
}

.so1screen__estrenoserie__left img {
  max-width: 200px;
  padding: 20px 0;
}

.so1screen__estrenoserie__left__genres {
  font-size: 1.4rem;
  opacity: 0.8;
  font-weight: lighter;
}

.so1screen__estrenoserie__left__synopsis {
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 20px 0;
}

.so1screen__estrenoserie__video {
  padding: 20px 0;
}

.so1screen__estrenoserie__video button {
  background-color: var(--unishoper-one);
  border-radius: 20px;
  color: var(--unishoper-zero);
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
}

.so1screen__estrenoserie__video button:hover {
  color: var(--unishoper-three);
  transition: all 0.5s ease;
}

.so1screen__estrenoserie__right {
  flex: 1;
}

/* Phones and Tablet*/

@media (max-width: 799px) {

  .loginScreen__cover__main {
    flex-direction: column;
  }

  .loginScreen__cover__contents {
    font-size: 1.2rem;
    max-width: 450px;
    height: 70vh;
    padding: 20px;
    justify-content: center;
    z-index: 99;
    flex: 1;
    margin: 0 auto;
  }

  .loginScreen__cover__contents > h1 {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  .loginScreen__cover__img img{
    width: auto;
    width: 100%;
    height: 100%;
  }

  .so1screen__estrenoserie {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }
  
  .so1screen__estrenoserie__left {
    flex: 1;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin: 20px;
    padding: 40px;
    height: 100%;
  }
  
  .so1screen__estrenoserie__left__soon {
    font-family: IntroDemo-BlackCapsInline;
    font-weight: Lighter;
    letter-spacing: 0.2rem;
    font-size: 1.8rem;
  }
  
  .so1screen__estrenoserie__left img {
    max-width: 250px;
    padding: 20px 0;
  }
  
  .so1screen__estrenoserie__left__genres {
    font-size: 1.4rem;
    opacity: 0.8;
    font-weight: lighter;
  }
  
  .so1screen__estrenoserie__left__synopsis {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 20px 0;
  }
  
  .so1screen__estrenoserie__video {
    padding: 20px 0;
  }
  
  .so1screen__estrenoserie__right {
    display: none;
  }

  .loginScreen__features {
    display: flex;
    flex-direction: column;
  }
  
  .loginScreen__features__title {
    justify-content: center;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
  }
  
  .loginScreen__features__title h2 {
    color: var(--unishoper-zero);
    font-family: IntroDemo-BlackCapsInline;
    font-weight: Lighter;
    letter-spacing: 0.2rem;
    font-size: 1.8rem;
    padding: 20px 0;
  }
  
  .loginScreen__features__title p {
    opacity: 0.8;
    font-size: 1.4rem;
    font-weight: 100;
  }
  
  .loginScreen__featvideo {
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    padding: 0 20px;
  }
  
  .loginScreen__featvideo h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 1.4rem;
    font-weight: lighter;
    padding: 0;
  }
  
  .loginScreen__featvideo h3 > span {
    font-family: IntroDemo-BlackCapsInline;
    font-weight: Lighter;
    letter-spacing: 0.2rem;
    font-size: 2.8rem;
    padding: 0 20px 0 0;
  }
  
  .loginScreen__featvideo video {
    flex: 1;
    padding: 40px 20px;
    justify-content: center;
    margin: 0;
  }
  
  .loginScreen__featimg {
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    padding: 0 20px;
  }
  
  .loginScreen__featimg img {
    flex: 1;
    padding: 40px 0;
    width: 300px;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
  }
  
  .loginScreen__featimg h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 1.4rem;
    font-weight: lighter;
    padding: 0;
  }
  
  .loginScreen__featimg h3 > span {
    font-family: IntroDemo-BlackCapsInline;
    font-weight: Lighter;
    letter-spacing: 0.2rem;
    font-size: 2.8rem;
    padding: 0 20px 0 0;
  }
  
}

/* Tablet and small laptop */
@media (min-width: 800px) {
  
}

/* Laptop */
@media (max-width: 992px) {
  
}

/* Desktop */
@media (max-width: 1300px) {
  
}
