option {
    color: #111;
    padding: 5px 0;
}

.checkoutguestScreen__resumen {
    display: flex;
    flex-direction: column;
    padding: 20px 80px 10px 80px;
}

.checkoutguestScreen__resumen__title {
    font-size: 1.4rem;
    text-align: center;
    border-bottom: solid 1px rgba(255, 0, 0, 0.2);
    padding-bottom: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.checkoutguestScreen__resumen__title span {
    opacity: 0.8;
    font-size: 1rem;
    color: var(--unishoper-three);
    font-weight: 600;
}

.checkoutguestScreen__resumen__math  {
    display: flex;
    flex-direction: column;
}

.checkoutguestScreen__resumen__inline {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    padding-bottom: 5px;
}

.checkoutguestScreen__continueshopping {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 40px 20px;
    text-align: center;
    background-color: var(--unishoper-zero);
    margin-top: 20px;
}

.checkoutguestScreen__continueshopping p {
    font-size: 0.8rem;
    padding: 10px 0;
}

.checkoutguestScreen__continueshopping button {
    background-color: var(--unishoper-one);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    color: var(--almerqao-three);
    padding: 10px;
}

.checkoutguestScreen__continueshopping button:hover {
    background-color: #111;
    transition: all 0.5s ease;
    color: var(--unishoper-zero);
}

.checkoutguestScreen__tabs {
    background-color: var(--unishoper-one);
    padding: 20px 0;
}

.checkoutguestScreen__title {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px 0 30px 0;
}

.checkoutguestScreen__title span {
    opacity: 0.8;
    font-size: 1rem;
    color: var(--unishoper-three);
    font-weight: 600;
}

.checkoutguestScreen__yape {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.checkoutguestScreen__yape img {
    margin: 0 auto;
    max-width: 300px;
    justify-content: center;
    text-align: center;
}

.checkoutguestScreen__yape p {
    font-size: 1.4rem;
    text-align: center;
    padding: 5px 0;
    font-weight: 700;
    align-items: center;
}

.checkoutguestScreen__yape span {
    font-size: 1rem;
    text-align: center;
}

.checkoutguestScreen__transferencia {
    text-align: center;
    padding: 20px;
}

.checkoutguestScreen__data {
    padding: 20px;
}

.checkoutguestScreen__data__title {
    text-align: center;
    padding: 20px 0 30px 0;
    display: flex;
    flex-direction: column;
}

.checkoutguestScreen__data__title span {
    opacity: 0.8;
    font-size: 1rem;
    color: var(--unishoper-three);
    font-weight: 600;
}

.checkoutguestScreen__data__form {
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.checkoutguestScreen__data__form ul {
    padding: 0 20px;
}

.checkoutguestScreen__data__form ul li {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    width: 100%;
}

.checkoutguestScreen__data__form ul li input {
    padding: 10px 20px;
    background-color: var(--unishoper-zero);
    border: solid 1px var(--unishoper-one);
    margin: 10px 0;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    width: 100%;
}

.checkoutguestScreen__data__form ul li input::placeholder {
    opacity: 0.8;
}

.checkoutguestScreen__data__form__select p {
    font-size: 1rem;
    text-align: center;
    padding-top: 10px;
}

.checkoutguestScreen__data__form__select select {
    padding: 10px 20px;
    background-color: var(--unishoper-zero);
    border: solid 1px var(--unishoper-one);
    margin: 10px 0;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    width: 100%;
}

.checkoutguestScreen__paybutton {
    display: flex;
    padding: 0 40px 80px 40px;
}

.checkoutguestScreen__paybutton button {
    margin: 0 auto;
    padding: 20px;
    background-color: var(--unishoper-one);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    color: var(--unishoper-zero);
    justify-content: center;
    text-align: center;
    width: 100%;
}

.checkoutguestScreen__paybutton button:hover {
    background-color: #111;
    transition: all 0.5s ease;
    color: var(--unishoper-zero);
}

/* Mobile, Tablet and small laptop */
@media (max-width: 799px) {
  
}

/* Laptop */
@media (max-width: 992px) {
  
}

/* Desktop */
@media (max-width: 1300px) {
  
}